import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class KarateDoTools extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Karate-do/Tools (My way of life/Arts) - Graham Ravey</h1>
                <div className="libraryImages">
                    <StaticImage src="../../images/KarateDoTools.jpg" alt="Sanchin"/>
                </div>

                <p>Armed with "Tools" that can only be obtained by many years of traditional Karate practice students
                    may then choose subjects and mould then into art forms much quicker, but first we must realize what
                    "the tools" are.</p>

                <p>Training in a traditional style with a knowledgeable teacher over a lengthy period of time builds
                    positive qualities such as focus; strength of character, discipline, timing, will power etc.</p>

                <p>If looked upon as tools, these can help us defeat any task set upon us by "life itself" not only that
                    but these "tools" assist us to pick up other art forms much quicker because we already understand an
                    art (traditional Karate).</p>

                <p>I will give you my own example. In my case I chose the art of music. I heard that some Karate masters
                    became multi talented as time went by and selected such art forms like music, dancing, singing and
                    painting to pursue. So I decided to play a musical instrument, the harmonica. I rationalized I had a
                    "tool" to use. This was diaphragm breathing and breath control taught to me over the years by Goju
                    Sanchin Kata. And knowing the structure of teaching Karate, gave me the "tool" to learn to play. I
                    related the formats of each art and found them to be similar. For example:</p>

                <Row>
                    <Col><strong>Karate</strong></Col><Col><strong>Harmonica</strong></Col>
                </Row>
                <Row>
                    <Col>warm up</Col><Col>breathing exercises</Col>
                </Row>
                <Row>
                    <Col>basics</Col><Col>do.re.me.fa.so la.te.do</Col>
                </Row>
                <Row>
                    <Col>sandan gi</Col><Col>riffs(3 basic notes strung together)</Col>
                </Row>
                <Row>
                    <Col>kata</Col><Col>cover or classical songs</Col>
                </Row>
                <Row>
                    <Col>kumite</Col><Col>jamming (playing with others)</Col>
                </Row>

                <p>So the more I applied my Karate tools to the Harmonica the quicker I learnt the art form.</p>

                <p>Enjoy your training and dont forget to apply the tools, otherwise they lay idle. The Japanese made
                    art forms out of most everything from drinking tea to swordsman ship.</p>

                <p><strong>Sensei Graham Ravey</strong></p>
            </Layout>);
    }
}

export default KarateDoTools;
